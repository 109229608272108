<template>
<div>

    <div v-if="client_width >= 1300">
        <div class="footer">
            <div class="intro">
                <div style="margin:15px auto;">
                    <h1><span style="font-size: 14px;">不出名CAJ转PDF | 不限制CAJ格式文件大小 | 在线免费CAJ转换PDF</span></h1>
                </div>
                <div style="margin:5px auto;">
                    <span style="color:#777;font-size:85%">Powered By <a href="https://caj.bookcodes.cn">不出名CAJ转PDF</a></span>
                </div>
                <div style="margin:15px auto;">
                    <a href="http://www.beian.gov.cn/portal/index.do"><span style="font-size: 14px;">渝ICP备19027418号-2</span></a>
                </div>
            </div>
        </div>
    </div>

    <div v-else class="footer-mobile intro">
        <div>
            <span style="color:#777;font-size:85%">Powered By</span>
        </div>
        <div>
            <span style="color:#777;font-size:85%"><a href="https://bookcodes.cn"> 不出名CAJ转PDF </a></span>
        </div>
        <br />
        <div>
            <a href="http://www.beian.gov.cn/portal/index.do"><span style="font-size: 14px;">渝ICP备19027418号-2</span></a>
        </div>
    </div>
</div>
</template>

<script>
import {
    
} from "@/api/api.js";
export default {
    data() {
        return {
            client_width: null,
        };
    },
    methods: {

    },
    mounted() {
        this.client_width = document.body.clientWidth;
        window.onresize = function () {
            this.client_width = document.documentElement.clientWidth;
            console.log("宽度", document.documentElement.clientWidth);
            console.log("高度", document.documentElement.clientHeight);
        };
    }
};
</script>

<style scoped>
.footer {
    margin: 120px auto;
    bottom: 0;
    z-index: 100;
    height: 160px;
    width: calc(100vw - 200px);
    background-color: transparent;
    border-top: 1px solid #dddddd;
    border-radius: 5px;
    border-top: 1px solid #ddd;
}

.footer-mobile {
    margin: 0px auto;
    bottom: 0;
    z-index: 100;
    height: auto;
    background-color: transparent;
}

.intro {
    font-family: "微软雅黑";
    color: #9ea7b4;
    margin: 8px auto;
    text-align: center;
    background-color: transparent;
}
</style>
